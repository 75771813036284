import React from "react";
import { connect } from "react-redux";
import { toggleSidebar } from "../redux/actions/sidebarActions";
import { auth } from "../../src/firebase";
import * as types from "../redux/constants";
import CookiesDisclaimer from '../pages/notifications/CookiesDisclaimer';
import 'react-notifications-component/dist/theme.css'
import { store } from 'react-notifications-component';
import WarningSound from "../assets/sounds/alert_high-intensity.wav";
import ErrorSound from "../assets/sounds/error_alert.wav";

import {
  Row,
  Col,
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import {
  AlertCircle,
  Bell,
  Home,
  Settings,
  UserPlus
} from "react-feather";

var notifications = [];

const NavbarDropdown = ({
  children,
  count,
  showBadge,
  header,
  footer,
  icon: Icon
}) => (
  <UncontrolledDropdown nav inNavbar className="mr-2">
    <DropdownToggle nav className="nav-icon dropdown-toggle">
      <div className="position-relative">
        <Icon className="align-middle" size={18} />
        {showBadge ? <span className="indicator">{count}</span> : null}
      </div>
    </DropdownToggle>
    <DropdownMenu right className="dropdown-menu-lg py-0">
      <div className="dropdown-menu-header position-relative">
        {count} {header}
      </div>
      <ListGroup>{children}</ListGroup>
      <DropdownItem header className="dropdown-menu-footer">
        <span className="text-muted">{footer}</span>
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

const NavbarDropdownItem = ({ icon, title, description, link, time, spacing }) => (
  <ListGroupItem>
    <Row noGutters className="align-items-center">
      <Col xs={2}>{icon}</Col>
      <Col xs={10} className={spacing ? "pl-2" : null}>
        <div className="text-dark">{title}</div>
        <div className="text-muted small mt-1">{description}</div>
        <div className="text-muted small mt-1">Please find a link with details on how to proceed <a target="_blank" rel="noopener noreferrer" href={link}>here</a></div>
        <div className="text-muted small mt-1">{time}</div>
      </Col>
    </Row>
  </ListGroupItem>
);

class NavbarComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      notification_update_iter: 0
    }
  }

  updateNotifications(flaskNotifications) {
    var prev_notifications = notifications;
    notifications = [];

    // update notifications based on flask data
    for (let i = 0; i < flaskNotifications.length; i++) {
      notifications.push(flaskNotifications[i])

      // validate if notification is new
      var is_new = true
      for (let j = 0; j < prev_notifications.length; j++) {
        if (
          (flaskNotifications[i].title === prev_notifications[j].title) && 
          (flaskNotifications[i].description === prev_notifications[j].description) && 
          (flaskNotifications[i].type === prev_notifications[j].type)) {

          is_new = false;
          break;
        }
      }

      // display dismissible notification in UI
      if (is_new && (this.state.notification_update_iter > 0)) {
        store.addNotification({
          title: flaskNotifications[i]['title'],
          message: flaskNotifications[i]['description'],
          type: flaskNotifications[i]['type'],
          insert: "top",
          container: "top-left",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 0,
            click: true,
            showIcon: true
          }
        });

        // play audio notification
        if (flaskNotifications[i]['type'] === 'warning') {
          let WarningAudio = new Audio(WarningSound);
          WarningAudio.play();
        } else {
          let ErrorAudio = new Audio(ErrorSound);
          ErrorAudio.play();
        }
      }
    }

    this.setState({
      notification_update_iter: this.state.notification_update_iter + 1
    });
  }

  updateFlaskData() {
    this.jsonData = {};

    if (auth.currentUser) {
      auth.currentUser.getIdToken().then(token => {

        // fetch data
        fetch('/api/flask_data' + token).then(res => res.json()).then(data => {
          this.jsonData = JSON.parse(data.flask_data);

          // store data in localStorage
          if(Object.keys(this.jsonData).length !== 0) {
            localStorage.setItem('time_now', this.jsonData["time_now"]);
            localStorage.setItem('pnl_day', this.jsonData["pnl_day"]);
            localStorage.setItem('pnl_spread_day', this.jsonData["pnl_spread_day"]);
            localStorage.setItem('pnl_variance_day', this.jsonData["pnl_variance_day"]);
            localStorage.setItem('pnl_hour', this.jsonData["pnl_hour"]);
            localStorage.setItem('pnl_spread_hour', this.jsonData["pnl_spread_hour"]);
            localStorage.setItem('pnl_variance_hour', this.jsonData["pnl_variance_hour"]);
            localStorage.setItem('pnl_per_contract_hour', this.jsonData["pnl_per_contract_hour"]);
            localStorage.setItem('volume_traded_hour', this.jsonData["volume_traded_hour"]);
            localStorage.setItem('realized_pnl_session', this.jsonData["realized_pnl_session"]);
            localStorage.setItem('unrealized_pnl_session', this.jsonData["unrealized_pnl_session"]);
            localStorage.setItem('ed_microprice', this.jsonData["ed_microprice"]); 
            localStorage.setItem('ed_change_day', this.jsonData["ed_change_day"]); 
            localStorage.setItem('var_99', this.jsonData["var"]);
            localStorage.setItem('span', this.jsonData["span"]); 
            localStorage.setItem('bid_pass_spreads_mean', this.jsonData["bid_pass_spreads_mean"]);
            localStorage.setItem('ask_pass_spreads_mean', this.jsonData["ask_pass_spreads_mean"]); 
            localStorage.setItem('pass_spreads_mean', this.jsonData["pass_spreads_mean"]);
            localStorage.setItem('bid_fill_rates_mean', this.jsonData["bid_fill_rates_mean"]);
            localStorage.setItem('ask_fill_rates_mean', this.jsonData["ask_fill_rates_mean"]); 
            localStorage.setItem('fill_rates_mean', this.jsonData["fill_rates_mean"]); 
            localStorage.setItem('bid_pass_quantities_mean', this.jsonData["bid_pass_quantities_mean"]);
            localStorage.setItem('ask_pass_quantities_mean', this.jsonData["ask_pass_quantities_mean"]); 
            localStorage.setItem('pass_quantities_mean', this.jsonData["pass_quantities_mean"]); 
            localStorage.setItem('ping_status', this.jsonData["ping_status"]); 
            localStorage.setItem('outstanding_order_volume', this.jsonData["outstanding_order_volume"]); 
            localStorage.setItem('dgm_nav', this.jsonData["dgm_nav"]); 
            localStorage.setItem('dgm_pnl', this.jsonData["dgm_pnl"]);
            localStorage.setItem('dgm_1m_pnl', this.jsonData["dgm_1m_pnl"]);
          }
          
          let permissions = localStorage.getItem('permissions')
          var flaskNotifications = this.jsonData["notifications"]
          if ((permissions >= types.MIN_ADMIN_PERMISSIONS) && flaskNotifications) {
            this.updateNotifications(flaskNotifications);
          }
        }).catch(err => console.log(err))
      });
    }
  }

  flushLocalStorage() {
    localStorage.removeItem('time_now');
    localStorage.removeItem('pnl_day');
    localStorage.removeItem('pnl_spread_day');
    localStorage.removeItem('pnl_variance_day');
    localStorage.removeItem('pnl_hour');
    localStorage.removeItem('pnl_spread_hour');
    localStorage.removeItem('pnl_variance_hour');
    localStorage.removeItem('pnl_per_contract_hour');
    localStorage.removeItem('volume_traded_hour');
    localStorage.removeItem('realized_pnl_session');
    localStorage.removeItem('unrealized_pnl_session');
    localStorage.removeItem('ed_microprice'); 
    localStorage.removeItem('ed_change_day'); 
    localStorage.removeItem('var_99');
    localStorage.removeItem('span'); 
    localStorage.removeItem('bid_pass_spreads_mean');
    localStorage.removeItem('ask_pass_spreads_mean'); 
    localStorage.removeItem('pass_spreads_mean');
    localStorage.removeItem('bid_fill_rates_mean');
    localStorage.removeItem('ask_fill_rates_mean'); 
    localStorage.removeItem('fill_rates_mean'); 
    localStorage.removeItem('bid_pass_quantities_mean');
    localStorage.removeItem('ask_pass_quantities_mean'); 
    localStorage.removeItem('pass_quantities_mean'); 
    localStorage.removeItem('ping_status'); 
    localStorage.removeItem('outstanding_order_volume'); 
    localStorage.removeItem('dgm_nav'); 
    localStorage.removeItem('dgm_pnl');
    localStorage.removeItem('dgm_1m_pnl');
  }

  showCookiesDisclaimer() {
    if(localStorage.getItem('cookies_disclaimer_dismissed') == null) {
      this.showCookiesDisclaimer = true;
    }
  }

  componentDidMount() {
    this.flushLocalStorage();
    this.authenticateUser();
    this.showCookiesDisclaimer();
    this.updateFlaskData();

    this.timer = setInterval(this.updateFlaskData.bind(this), 5000);
  }

  componentWillUnmount() {
    clearInterval(this.timer)
    this.authCall && this.authCall();
  }

  authenticateUser() {
    this.authCall = auth.onAuthStateChanged(function(user) {
      if (!user) {
        window.location.href = types.REACT_ROOT + types.SIGN_IN;
      }
    });
  }
  
  attemptSignOut() {
    auth.signOut().then(function() {
      window.location.href = types.REACT_ROOT + types.SIGN_IN;
      console.log("Sign out successful")
    });
  }

  render() {
    const { dispatch } = this.props;

    return (
      <Navbar color="white" light expand > 
        <CookiesDisclaimer showCookiesDisclaimer={this.showCookiesDisclaimer} />
        <span
          className="sidebar-toggle d-flex mr-2"
          onClick={() => {
            dispatch(toggleSidebar());
          }}
        >
          <i className="hamburger align-self-center" />
        </span>

        <Collapse navbar>
          <Nav className="ml-auto" navbar>
            <NavbarDropdown
              header="New Notifications"
              footer="No further notifications"
              icon={Bell}
              count={notifications.length}
              showBadge
            >
              {notifications.map((item, key) => {
                let icon = <Bell size={18} className="text-warning" />;

                if (item.type === "warning") {
                  icon = <Bell size={18} className="text-warning" />;
                }

                if (item.type === "danger") {
                  icon = <AlertCircle size={18} className="text-danger" />;
                }

                if (item.type === "login") {
                  icon = <Home size={18} className="text-primary" />;
                }

                if (item.type === "request") {
                  icon = <UserPlus size={18} className="text-success" />;
                }

                return (
                  <NavbarDropdownItem
                    key={key}
                    icon={icon}
                    title={item.title}
                    description={item.description}
                    link={item.link}
                    time={item.time}
                  />
                );
              })}

            </NavbarDropdown>
            <UncontrolledDropdown nav inNavbar>
              <span className="d-inline-block d-sm-none">
                <DropdownToggle nav caret>
                  <Settings size={18} className="align-middle" />
                </DropdownToggle>
              </span>
              <span className="d-none d-sm-inline-block">
                <DropdownToggle nav caret>
                  <span className="text-dark" style={{marginRight: '3px'}}>{
                    (localStorage.getItem('displayName') !== 'null') ? localStorage.getItem('displayName') : "Guest Account"
                  }</span>
                </DropdownToggle>
              </span>
              <DropdownMenu right>
                <DropdownItem href={types.REACT_ROOT + types.TERMS}>Settings & Privacy</DropdownItem>
                <DropdownItem href="mailto:enrico@deepgreyresearch.com">Help</DropdownItem>
                <DropdownItem href={types.REACT_ROOT + types.JUPYTER}>Jupyter</DropdownItem>
                <DropdownItem onClick = {_ => {this.attemptSignOut()}}>Sign out</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
};

export default connect(store => ({
  app: store.app
}))(NavbarComponent);
