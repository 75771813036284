import React from "react";
import firebase from "firebase/app";
import DGR_Background from "../../assets/img/logos/DGR_Background.mov";
import DGM_logo from "../../assets/img/logos/DGM_logo_full.png";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Button } from "reactstrap";
import { auth, firestore } from "../../../src/firebase";
import { analytics } from "../../../src/firebase";
import TextField from '@material-ui/core/TextField';

import './AuthSignIn.css';
const alt_photo_url = 'https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png';

export default class SignIn extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      toggle: false
    };
  }

  expandBox() {
    this.setState({
      toggle: true
    });
  }

  closeBox() {
    this.setState({
      toggle: false
    });
  }

  componentDidMount() {
    this.authCall = auth.onAuthStateChanged(async (user) => {
      if (user) {
        analytics.setUserId(auth.currentUser.displayName)
        analytics.logEvent('login', { user: auth.currentUser.displayName });

        const user_doc = await firestore.collection('users').doc(auth.currentUser.uid).get();
        if (user_doc.exists) {
          const user_data = user_doc.data();
          localStorage.setItem('displayName', user_data.name);
          localStorage.setItem('uid', user_data.id);
          localStorage.setItem('email', user_data.email);
          localStorage.setItem('permissions', user_data.permissions);
          if (user_data.photoURL) localStorage.setItem('photoPath', user_data.photoURL);
          else localStorage.setItem('photoPath', alt_photo_url);
          window.location.href = '/ed_sofr/control';
        }
      }
    });
  }

  componentWillUnmount() {
    this.authCall && this.authCall();
  }

  attemptAdminSignIn() {
    var provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({'hd': 'deepgreymarkets.com'});
    auth.signInWithPopup(provider)
      .then(result => {
        if (!result.user.email.endsWith('deepgreymarkets.com')) {
          alert('Login failed: user not part of organization.');
          auth.signOut();
        }
      })
      .catch(error => {
        alert(error.message);
      });
  }

  attemptInvestorSignIn() {
    this.setState({
      toggle: false
    });
    var email = document.getElementById('email_input').value
    var password = document.getElementById('password_input').value
    auth.signInWithEmailAndPassword(email, password)
      .catch((error) => {
        alert(error.message);
      });
  }

  render() {
    
    return(
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', background:'black'}}>
          <video className='videoTag' autoPlay loop muted style={{position: 'fixed', right: '0', bottom: '0', minWidth: '100%', minHeight: '100%'}}>
            <source src={DGR_Background} type='video/mp4' />
          </video>
          <div style={{position: 'fixed', bottom: '0', background: 'rgba(0, 0, 0, 0.6)', color: '#f1f1f1', width: '100%', height: '100%', padding: '40px'}}>
            <div className="centerAssets">
                <div style={{display: 'inline-block', paddingBottom:'5vh', maxWidth:"900px"}}>
                    <img src={DGM_logo} loading="lazy" width="90%" alt="Deep Grey Markets"></img>
                </div>

                <div style={{width:'100%', marginBottom: '2vh'}} data-ix="fade-in-bottom-page-loads" className="hero-subheading">Welcome to the Deep Grey Markets Trading Cockpit</div>

                <div style={{padding: '0', margin: '0', display: 'flex'}}>
                  <div class='row'>
                    <div class='column' style={{marginTop:'2vh', marginLeft:'1.5vw'}}>
                      <div className="LoginText" onClick={(_) => {this.attemptAdminSignIn()}}>
                          <div className="LoginButton">
                              Internal Portal
                          </div>
                      </div>
                    </div>
                    <div class='column' style={{marginTop:'2vh'}} >
                      <div className="HollowLoginText" onClick={(_) => {this.expandBox()}}>
                          <div className="HollowLoginButton">
                              Investor Portal
                          </div>
                      </div>
                    </div>
                  </div>
                  <Dialog open={this.state.toggle} onClose={() => this.closeBox()} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title" style={{fontFamily:'Nunito'}}>Investor Sign-in</DialogTitle>
                    <DialogContent>
                      <DialogContentText style={{fontFamily:'Nunito'}}>
                        IMPORTANT: Do not share your login details with anyone. The Deep Grey Markets investor portal is for illustrative use only and the results are subject to change at anytime.
                      </DialogContentText>
                      <TextField autoFocus margin="dense" id="email_input" label="Email Address" type="email" style={{fontFamily:'Nunito'}} fullWidth />
                      <TextField autoFocus margin="dense" id="password_input" label="Password" type="password" style={{fontFamily:'Nunito'}} fullWidth />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => this.closeBox()} color="primary" style={{fontFamily:'Nunito'}}>
                        Cancel
                      </Button>
                      <Button onClick={() => this.attemptInvestorSignIn()} color="primary" style={{fontFamily:'Nunito'}}>
                        Login
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
            </div>
        </div>        
      </div>
    );
  }
};